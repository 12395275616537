import DialogStack from "@/components/jpbl/UI/dialog/stack";
import BaseDialogCtrl from "~/components/jpbl/UI/dialog/base/controller";

const ANIMATION_DURATION_MS = 500;

const dStack = DialogStack.getInstance();
const dialogStack = ref(dStack);
/**
 * VDialogから閉じられる場合、_showDialogが直接変わる。
 * close系メソッドで処理する場合は、falseにしてから_showDialogを変更する
 */
const closeForVDialog = ref(true);
const _showDialog = ref(false);

const ctrl = computed(() => dialogStack.value.top);

const showDialog = () => {
  closeForVDialog.value = true;
  _showDialog.value = true;
}

const closeDialog = () => {
  closeForVDialog.value = false;
  _showDialog.value = false;
}

watch(_showDialog, () => {
  if (closeForVDialog.value && _showDialog.value === false) {
    // v-dialogで背景がタップされた場合
    console.log("close VDialog")
    setTimeout(() => dialogStack.value.clear(), ANIMATION_DURATION_MS);
  }
})

/**
 * ダイアログを開く
 * @param {BaseDialogCtrl} dialogCtrl 
 * @return {void}
 */
const open = (dialogCtrl: BaseDialogCtrl): void => {
  dialogStack.value.push(dialogCtrl);
  showDialog();
};

/**
 * ダイアログを複数開く
 * @param {BaseDialogCtrl[]} queue 
 * @returns {void}
 */
const openMulti = (queue: BaseDialogCtrl[]): void => {
  if (queue.length > 0) {
    dialogStack.value.pushQueue(queue);
    showDialog();
  }
}

/**
 * 現在開いているダイアログを閉じる
 * @param {number} withParent 親ダイアログも閉じる場合はネスト数を指定(デフォルトは0で自分だけ)
 * @return {Promise<void>} - アニメーションが完了したら解決するプロミスを返す
 */
const close = (withParent: number = 0): Promise<void> => {
  console.log("called close");
  closeDialog();
  return new Promise((resolve) => {
    try {
      setTimeout(() => {
        do {
          dialogStack.value.pop();
        } while (0 < withParent--);
        if (dialogStack.value.top) showDialog();
        resolve();
      }, ANIMATION_DURATION_MS);
    } catch (error) { // エラーログを出力するものの、プロミスは解決される
      console.error(error);
      resolve();
    }
  })
};

/**
 * 現在開いているダイアログを閉じた上、ダイアログスタックを空にする
 * @return {void}
 */
const closeAll = (): void => {
  console.log("called close all");
  closeDialog();
  setTimeout(() => {
    dialogStack.value.clear();
    if (dialogStack.value.top) showDialog();
  }, ANIMATION_DURATION_MS);
}

export const useDialogAdapter = () => {
  return {
    dialogAdapter: {
      open,
      openMulti,
      close,
      closeAll
    },
    ctrl,
    showDialog: _showDialog,
  }
}
