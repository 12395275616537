import BaseDialogCtrl from "./base/controller";

export default class DialogStack {
  constructor() {
    if (DialogStack._instance) {
      console.error("Do not call the constructor of a Singleton class, DialogStack. Use getInstance() method instead.");
    }
  }

  /**
   * @returns {?BaseDialogCtrl}
   */
  get top() {
    return this._stack.length > 0 ? this._stack[this._stack.length - 1] : null;
  }

  /**
   * @param {BaseDialogCtrl} dialogCtrl 
   * @returns {void}
   */
  push(dialogCtrl) {
    this._stack.push(dialogCtrl);
  }

  /**
   * 引数で渡されたキューがFIFOの順に処理されるよう、リバースしてからスタックにプッシュする。
   * E.g. queue: [1,2,3] --push--> stack: [3,2,1]
   * @param {BaseDialogCtrl[]} queue 
   * @returns {void}
   */
  pushQueue(queue) {
    this._stack.push(...queue.reverse());
  }

  /**
   * @returns {void}
   */
  pop() {
    this._stack.pop();
  }

  /**
   * @returns {void}
   */
  clear() {
    this._stack = [];
  }

  /**
   * @type {BaseDialogCtrl[]}
   */
  _stack = [];

  /**
   * @type {DialogStack}
   */
  static _instance = null;

  static getInstance() {
    if (!DialogStack._instance) DialogStack._instance = new DialogStack();

    return this._instance;
  }
}
